<template>
    <div class="container py-1 mb-lg-1 toolbar_main">
        <div class="row justify-content-center pt-lg-4 text-center">
            <div class="col-lg-5 col-md-7 col-sm-9">
                <img class="d-block mx-auto mb-5" src="/img/404_claro.png" width="340" alt="404 Error">
                <h3 class="h5 fw-normal mb-4">Parece que no podemos encontrar la página que está buscando.</h3>
                <p class="fs-md mb-4">
                <router-link to="/" class="btn btn-secondary btn-lg">
                    <i class="icofont icofont-ui-home"></i>
                    Regresar al inicio
                </router-link>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapMutations, mapActions} from 'vuex';
    export default {
        name: 'Error404',
        computed:{
            ...mapState(['empresa']),
        },
        mounted(){
            this.loadObjPerfilUsuario();
            
            document.title = "Pagina no encontrada..!";
            document.body.classList.add('error-page-main');

            setTimeout(()=>{
                this.setLoading(false);
            }, 300);
        },
        methods:{
            ...mapMutations(['setLoading']),
            ...mapActions(['loadObjPerfilUsuario']),
        },
    }
</script>

<style scoped>
    .error-page-main{
      background-color: #a32121;
    }
</style>